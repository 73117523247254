<template>
  <div class="flex flex-row h-full bg-gray-200">
    <!-- <div class="bg-gray-800 w-64 h-full overflow-auto py-2" v-show="!$store.getters.isFullscreen"> -->
    <div
      v-show="!$store.getters.isFullscreen && !$store.getters.isCollapsed"
      class="bg-gray-800 w-64 flex flex-col"
    >
      <div
        v-if="$store.getters.isAuthenticated"
        class="p-4 border-b border-gray-700"
      >
        <el-input
          v-model="filterTree"
          placeholder="Cari Kamera"
          class="border-none"
        />
      </div>
      <div class="flex-grow overflow-auto">
        <el-tree
          v-if="user"
          ref="tree"
          :data="tree.data"
          :props="tree.defaultProps"
          :filter-node-method="filterNode"
          default-expand-all
          show-checkbox
          icon-class="el-icon-arrow-down"
          class="pl-3 pt-4"
          node-key="index"
          @check="onTreeCheck"
        >
          <template slot-scope="scope">
            <div :class="`ml-2 w-40 ${!scope.data.children ? (tree.checkedKeys.includes(scope.node.id) ? 'opacity-100' : 'opacity-25') : null}`">
              <div class="text-sm">
                {{ scope.data.label }}
              </div>
              <div
                v-if="scope.data.children"
                class="text-xs opacity-50"
              >
                {{ scope.data.children.length }} Kamera
              </div>
              <template v-if="!scope.data.children">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.data.data.address"
                  placement="right"
                >
                  <div class="truncate text-xs opacity-50">
                    {{ scope.data.data.address }}
                  </div>
                </el-tooltip>
              </template>
            </div>
          </template>
        </el-tree>
        
        <ul
          v-if="!user"
          v-infinite-scroll="load"
        >
          <li
            v-for="(camera, index) in cameras.data"
            :key="camera.name"
            :class="`${camera.isVisible ? 'text-white' : 'text-gray-500' } hover:text-white flex flex-col`"
          >
            <div class="flex justify-between items-center px-4 py-2">
              <div class="flex items-center">
                <i
                  :class="`ri-eye-line cursor-pointer ${camera.isVisible ? 'text-blue-500' : 'text-white'}`"
                  @click="toggleVisible(index)"
                />
                <div class="ml-4">
                  <span class="block text-sm font-bold">{{ camera.name }}</span>
                  <span class="block text-xs truncate w-40">{{ camera.address }}</span>
                </div>
              </div>
              <i
                class="cursor-pointer ri-arrow-down-s-line"
                @click="openInformation(index)"
              />
            </div>
            <div
              v-show="index === cameras.infoOpen"
              class="flex flex-col px-4 pl-12 pb-8"
            >
              <dl class="mb-2">
                <dt class="text-xs text-blue-600">
                  Address
                </dt>
                <dd class="text-xs text-white">
                  {{ camera.address }}
                </dd>
              </dl>
              <dl>
                <dt class="text-xs text-blue-600">
                  Latitude
                </dt>
                <dd class="text-xs text-white">
                  {{ camera.latitude }}
                </dd>
              </dl>
              <dl>
                <dt class="text-xs text-blue-600">
                  Longitude
                </dt>
                <dd class="text-xs text-white">
                  {{ camera.longitude }}
                </dd>
              </dl>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="text-gray-700 text-center bg-gray-400 flex-1">
      <div
        v-if="isFullscreen"
        class="w-full h-full flex flex-col"
      >
        <div class="flex-none bg-gray-500 h-8 flex items-center justify-end px-2 text-white text-xs">
          <span class="mr-auto">{{ cameras.data[selectedIndexFullscreen].name }}</span>
          <el-tooltip
            class="item"
            effect="dark"
            content="Kecilkan"
            placement="left"
          >
            <i
              class="cursor-pointer ri-fullscreen-exit-line ri-lg"
              @click="togglePseudoFullscreen"
            />
          </el-tooltip>
        </div>
        <div class="flex-grow bg-gray-400">
          <AutoReloadImage
            :src="`http://proxy.cctv.malangkota.go.id/image?host=${cameras.data[selectedIndexFullscreen].host}&t=${$moment().unix()}`"
          />
          <!-- <youtube-media player-width="100%" player-height="600" :player-vars="{ autoplay: 1 }" :video-id="cameras.data[0].videoId" /> -->
          <!-- <iframe src="http://cctv.balitower.co.id/Bendungan-Hilir-003-700014_1/embed.html" class="w-full h-full" frameborder="0"></iframe> -->
        </div>
      </div>
      <div
        v-if="cameras.active.length === 0"
        class="bg-gray-900 p-8"
      >
        <div v-if="!$store.getters.isAuthenticated">
          Lihat dan aktifkan kamera dengan klik icon mata <i class="ri-eye-line" /> di setiap kamera pada sidebar kiri 
        </div>
        <div v-if="$store.getters.isAuthenticated">
          Lihat dan aktifkan kamera dengan centang checkbox di setiap kamera atau cluster pada sidebar kiri 
        </div>
      </div>
      <div
        v-if="!isFullscreen"
        class="flex flex-col h-full"
      >
        <div
          v-for="(row, rowIndex) in cameras.grid[0]"
          :key="rowIndex"
          class="bg-gray-900 lg:flex-1 sm:flex sm:w-full"
        >
          <div class="flex flex-col lg:flex-row h-full w-full">
            <!-- <div class="bg-red-100 flex-1">
              <div class="bg-gray-500 h-8 flex items-center justify-end px-2 text-white text-xs">
                <span class="mr-auto">Camera</span>
                <el-tooltip class="item" effect="dark" content="Tampilan Penuh" placement="left">
                  <i class="cursor-pointer ri-fullscreen-line ri-lg"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Kirim Notifikasi" placement="top">
                  <i class="cursor-pointer ri-share-forward-fill ri-lg ml-3"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Sembunyikan" placement="top">
                  <i class="cursor-pointer ri-close-line ri-2x ml-2"></i>
                </el-tooltip>
              </div>
              <div class="h-full bg-green-200">
              </div>
            </div> -->
            <div
              v-for="(col, colIndex) in cameras.grid[1]"
              :key="colIndex"
              class="lg:flex-1 sm:flex sm:w-full"
            >
              <!-- {{ (colIndex + 1) + (rowIndex * cameras.grid[1]) }} -->
              <div
                v-if="cameras.active.length + 1 > ((colIndex + 1) + (rowIndex * cameras.grid[1]))"
                class="bg-red-100 h-full flex flex-col w-full"
              >
                <div class="bg-gray-900 h-8 flex items-center justify-end px-2 text-white text-xs">
                  <span class="mr-auto">{{ cameras.data[parseInt(cameras.active[(colIndex) + (rowIndex * cameras.grid[1])])].name }}</span>
                  <el-tooltip
                    v-if="(user && user.data.role_id.stream_mode === 'passive')"
                    class="item"
                    effect="dark"
                    content="Tampilan Penuh"
                    placement="left"
                  >
                    <i
                      class="cursor-pointer ri-fullscreen-line ri-lg"
                      @click="toggleFullscreen((colIndex + 1) + (rowIndex * cameras.grid[1]) - 1)"
                    />
                  </el-tooltip>
                  <el-tooltip
                    v-if="!user"
                    class="item"
                    effect="dark"
                    content="Tampilan Penuh"
                    placement="left"
                  >
                    <i
                      class="cursor-pointer ri-fullscreen-line ri-lg"
                      @click="togglePseudoFullscreen((colIndex + 1) + (rowIndex * cameras.grid[1]) - 1)"
                    />
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Kirim Notifikasi"
                    placement="top"
                  >
                    <i
                      v-show="$store.getters.isAuthenticated"
                      class="cursor-pointer ri-share-forward-fill ri-lg ml-3"
                      @click="notify(cameras.data[parseInt(cameras.active[(colIndex) + (rowIndex * cameras.grid[1])])])"
                    />
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Sembunyikan"
                    placement="top"
                  >
                    <i
                      v-if="!$store.getters.isAuthenticated"
                      class="cursor-pointer ri-close-line ri-2x ml-2"
                      @click="toggleVisible(parseInt(cameras.active[(colIndex) + (rowIndex * cameras.grid[1])]))"
                    />
                  </el-tooltip>
                </div>
                <div
                  ref="videoWrapper"
                  class="flex-grow"
                  :style="`height: ${videoHeight}`"
                >
                  <!-- {{ cameras.data[parseInt(cameras.active[(colIndex) + (rowIndex * cameras.grid[1])])] }} -->
                  <iframe
                    v-if="user && user.data.role_id.stream_mode === 'active'"
                    width="100%"
                    :height="videoHeight"
                    :src="`//stream.cctv.malangkota.go.id:/WebRTCApp/play.html?name=${cameras.data[parseInt(cameras.active[(colIndex) + (rowIndex * cameras.grid[1])])].stream_id}`"
                    frameborder="0"
                    allowfullscreen
                  />
                  <fullscreen
                    ref="fullscreen"
                    @change="fullscreenChange"
                  >
                    <AutoReloadImage
                      v-if="(user && user.data.role_id.stream_mode === 'passive') || !$store.getters.isAuthenticated"
                      :src="`http://proxy.cctv.malangkota.go.id/image?host=${cameras.data[parseInt(cameras.active[(colIndex) + (rowIndex * cameras.grid[1])])].host}&t=${$moment().unix()}`"
                    />
                  </fullscreen>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{ user }} -->
    <NotificationFormDialog
      ref="notification"
    />
  </div>
</template>

<script>

// import { getIdFromURL } from 'vue-youtube-embed'
import NotificationFormDialog from '../Notifications/NotificationForm.dialog';
import AutoReloadImage from '../../../components/AutoReloadImage.component';
import Fullscreen from 'vue-fullscreen/src/component.vue';
// import ImageComponent from './CameraImage.component';
// const axios = require('axios');
export default {
  components: {
    NotificationFormDialog,
    AutoReloadImage,
    Fullscreen
    // ImageComponent
  },
  data() {
    return {
      user: null,
      search: null,
      renderImage: true,
      interval: null,
      key: 0,
      cameras: {
        data: [],
        // key: 0,
        active: [],
        fullscreen: null,
        max: 16,
        grid: [4,4],
        infoOpen: null
      },
      isFullscreen: false,
      selectedIndexFullscreen: null,
      fullscreen: false,
      videoHeight: 0,
      filterTree: '',
      tree: {
        data: [],
        checkedKeys: [],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      }
    }
  },
  watch: {
    filterTree(val) {
      this.$refs.tree.filter(val);
    },
    '$store.getters.isAuthenticated'() {
      this.init();
    }
  },
  // async created() {
  //   const clusters = await this.getClusters();
  //   this.tree.data = clusters.records.map(cluster => {
  //     const children = cluster.cameras.map(camera => {
  //       this.cameras.data.push(camera);
  //       return {
  //         id: camera.id,
  //         label: camera.name,
  //         data: camera
  //       }
  //     })
  //     return {
  //       id: cluster.id,
  //       label: cluster.name,
  //       data: cluster,
  //       children
  //     }
  //   });
  // },
  async mounted() {
    const cameras = await this.getCameras();
    this.cameras.data = cameras.records;
    // console.log(this.user);
    if(!this.user && !this.$store.getters.isAuthenticated) {
      this.setDefaultVisibleCameras();
    } else {
      this.init();
    }
  },
  methods: {
    async init() {
      // console.log('init');
      // console.log('isAuthenticated', this.$store.getters.isAuthenticated);
      // console.log('user', this.$store.getters.getCurrentAuthUser);
      
      this.user = this.$store.getters.getCurrentAuthUser;
      if(this.$store.getters.isAuthenticated) {
        this.cameras.data = [];
        const clusters = await this.getClusters();
        let index = 0;
        clusters.records.forEach(cluster => {
          let children = [];
          cluster.cameras.forEach(camera => {
            this.cameras.data.push(camera);
            children.push({
              id: camera.id,
              index: index++,
              label: camera.name,
              data: camera
            })
          });
          this.tree.data.push({
            id: cluster.id,
            label: cluster.name,
            data: cluster,
            children
          })
        });
      }
      this.setDefaultVisibleCameras();
    },
    filterNode(value, data) {
      // console.log(data);
      if (!value) return true;
      const keyword = new RegExp(value.toLowerCase(), 'i');
      if(!data.children) {
        const name = data.label.toLowerCase();
        const address = data.data.address.toLowerCase();
        return address.match(keyword) || name.match(keyword);
      }
      // return name.match(keyword);
    },
    onTreeCheck() {
      this.cameras.active = [];
      const checkedKeys = this.$refs.tree.getCheckedKeys(true);
      checkedKeys.forEach(index => this.cameras.active.push(index));
      this.rememberToLocalStorage();
      this.$nextTick(() => {
        if(this.cameras.active.length > 0) {
          this.videoHeight = this.$refs.videoWrapper[0].offsetHeight;
        }
      })
      this.tree.checkedKeys = this.$refs.tree.getCheckedNodes(true).map(node => {
        return node.$treeNodeId
      })
    },
    getCameras() {
      return new Promise((resolve) => {
        const searchParams = new URLSearchParams();
        searchParams.append('filter', 'address,nis');
        searchParams.append('filter', 'latitude,nis');
        searchParams.append('filter', 'longitude,nis');
        searchParams.append('filter', 'stream_id,nis');
        searchParams.append('filter', 'status,eq,1');
        this.$request.get('records/cameras', { params: searchParams }).then(response => {
          resolve(response.data);
        })
      })
    },
    getClusters() {
      return new Promise((resolve) => {
        const searchParams = new URLSearchParams();
        searchParams.append('join', 'cameras');
        this.$request.get('records/clusters', { params: searchParams }).then(response => {
          resolve(response.data);
        })
      })
    },
    toggleVisible(index) {
      if(!this.cameras.data[index].isVisible && this.cameras.active.length > this.cameras.max) {
        // console.log('maximum reached!');
      } else {
        this.cameras.data[index].isVisible = !this.cameras.data[index].isVisible
        if(this.cameras.data[index].isVisible) {
          this.cameras.active.push(index);
        } else {
          this.cameras.active.splice(this.cameras.active.indexOf(index), 1);
        }
      }
      this.rememberToLocalStorage();
      this.$nextTick(() => {
        if(this.cameras.active.length > 0) {
          this.videoHeight = this.$refs.videoWrapper[0].offsetHeight;
        }
      })
    },
    setDefaultVisibleCameras() {
      let camerasIndexes = [];
      this.cameras.active = [];
      if(window.localStorage.getItem('rememberCameras')) {
        const rememberCameras = JSON.parse(window.localStorage.getItem('rememberCameras'));
        camerasIndexes = (this.$store.getters.isAuthenticated) ? rememberCameras.private : rememberCameras.public;
      }
      camerasIndexes.forEach(index => {
        this.toggleVisible(index)
      });
      // console.log(this.cameras.active);
      // console.log(this.$refs.tree);
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.cameras.active);
      })
    },
    rememberToLocalStorage() {
      const rememberCameras = (window.localStorage.getItem('rememberCameras')) ? JSON.parse(window.localStorage.getItem('rememberCameras')) : { private: [], public: [] };
      if(this.$store.getters.isAuthenticated) {
        rememberCameras.private = this.cameras.active;
      } else {
        rememberCameras.public = this.cameras.active;
      }
      window.localStorage.setItem('rememberCameras', JSON.stringify(rememberCameras));
    },
    openInformation(index) {
      if(this.cameras.infoOpen === index) {
        this.cameras.infoOpen = []
      } else {
        this.cameras.infoOpen = index;
      }
    },
    notify(cameraData) {
      this.$refs.notification.openDialog();
      this.$refs.notification.setCameraData(cameraData);
    },
    toggleFullscreen(index) {
      // console.log('toggle fullscreen')
      // this.isFullscreen = !this.isFullscreen;
      // console.log(this.$refs);
      // console.log(this.$refs['fullscreen'][index]);
      this.$refs['fullscreen'][index].toggle();
      // console.log(index);
    },
    togglePseudoFullscreen(index) {
      this.isFullscreen = !this.isFullscreen;
      this.selectedIndexFullscreen = index;
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    getImageElement(url) {
      // console.log(url);
      return '<h1>' + url + '</h1>';
      // await this.$request.get(`http://proxy.cctv.indux.id/image?host=${url}`).then(response => {
      // const imgObj = await axios.get(`http://localhost:62341/image?host=${url}`).then(response => {
      //   // console.log(response);
      //   return response.data;
      // });
      //   // return response;
      // return imgObj;
      // return `http://localhost:62341/image?host=${url}`;
      // console.log(url);
    },
    load() {
      return true;
    },
    handleOpen() {

    },
    handleClose() {

    }
    
  }
}
</script>

<style>
.el-tree-node__content {
  @apply py-6;
  padding-left: 0px !important;
}
.el-tree-node__content:hover,
.el-tree-node__content:focus {
  background: none;
}
.el-tree {
  background: transparent;
}
</style>