<template>
  <el-dialog
    v-loading="dialog.loading"
    :visible.sync="dialog.open"
    title="Notifikasi"
    width="80%"
    :show-close="false"
    :before-close="() => {}"
    @close="onDialogClose"
  >
    <div
      v-if="state === 'confirm'"
      class="mb-8"
    >  
      <el-alert
        title="Pesan peringatan untuk kirim notifikasi ke WhatsApp"
        type="warning"
        description="Demi kelancaran pengiriman notifikasi ke whatsapp, silahkan bla.. bla.."
        show-icon
        :closable="false"
      />
    </div>

    <el-form
      :key="key"
      :model="form"
      label-position="top"
    >
      <el-form-group
        name="Group"
        label="Group"
        :validator="$v.form.group_id"
      >
        <el-select
          v-model="form.group_id"
          class="w-full"
          placeholder="Select Group"
          :disabled="state === 'confirm'"
          @change="onSelectGroup"
        >
          <el-option
            v-for="item in options.groups"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-group>
      <el-form-group
        v-if="tree.data.length > 0"
        name="Group Member"
        label="Group Member"
      >
        <el-tree
          v-if="state === 'form'"
          ref="tree"
          :data="tree.data"
          show-checkbox
          :node-key="tree.key"
          :props="tree.props"
          default-expand-all
        />
        <template v-if="state === 'confirm'">
          <el-table :data="tree.checked.nodes">
            <el-table-column
              label="Name"
              prop="full_name"
            />
            <el-table-column prop="email">
              <template
                slot="header"
                slot-scope="scope"
              >
                <el-checkbox
                  v-model="enable.email"
                  :class="scope.$index"
                >
                  Email
                </el-checkbox>
              </template>
              <template slot-scope="scope">
                <span :class="enable.email ? 'opacity-100' : 'opacity-25'">
                  {{ scope.row.email }}
                </span>  
              </template>
            </el-table-column>
            <el-table-column prop="phone_number">
              <template
                slot="header"
                slot-scope="scope"
              >
                <el-checkbox
                  v-model="enable.whatsapp"
                  :class="scope.$index"
                >
                  No Telpon WhatsApp
                </el-checkbox>
              </template>
              <template slot-scope="scope">
                <span :class="enable.whatsapp ? 'opacity-100' : 'opacity-25'">
                  {{ scope.row.phone_number }}
                </span>  
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-form-group>
      
      <div class="flex">
        <div :class="state === 'form' ? 'w-full' : 'w-1/2'">
          <el-form-group
            :label="state === 'form' ? 'Tulis pesan yang akan dikirim' : 'Isi pesan yang akan dikirim'"
          >
            <el-input
              v-show="state === 'form'"
              v-model="form.message"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 10}"
              placeholder="Isi Pesan Notifikasi"
              :disabled="state === 'confirm'"
            />
            <el-alert
              v-show="state === 'confirm'"
              :closable="false"
              type="success"
              v-html="message.html"
            />
          </el-form-group>
        </div>
        <div
          v-if="state === 'confirm'"
          class="w-1/2 pl-8"
        >
          <el-form-group
            name="Foto Terkini Kamera"
            label="Foto Terkini Kamera"
          >
            <img
              :src="url.image"
              alt=""
            >
          </el-form-group>
        </div>
      </div>
    </el-form>

    <div
      slot="footer"
      class="dialog-footer flex justify-between"
    >
      <div />
      <div>
        <el-button @click="onCancelClick">
          {{ dialog.label.cancel }}
        </el-button>
        <el-button
          type="primary"
          :disabled="($v.$invalid && url.image === null) || (this.state === 'confirm' && !this.enable.whatsapp && !this.enable.email)"
          @click="onOkClick"
        >
          {{ dialog.label.ok }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      state: 'form',
      dialog: {
        open: false,
        label: {
          ok: 'Next',
          cancel: 'Cancel'
        },
        loading: false
      },
      options: {
        groups: []
      },
      form: {},
      key: 0,
      camera: {},
      tree: {
        data: [],
        props: {
          label: 'label'
        },
        key: 'id',
        checked: {
          keys: [],
          nodes: []
        }
      },
      // prefixMessage: null,
      imageCloudinary: null,
      url: {
        camera: null,
        image: null
      },
      message: {
        prefixes: [
          '[CCTV Kominfo Kota Malang]'
        ],
        postfixes: [
          'Kunjungi link berikut untuk melihat kondisi kamera CCTV terkait: <br>{camera_url}',
          'Kunjungi link berikut untuk melihat tangkapan layar CCTV saat pesan ini dikirim: <br>{image_url}',
        ],
        original: null,
        encoded: null,
        html: null
      },
      enable: {
        whatsapp: true,
        email: true,
      }
    }
  },
  async mounted() {
    if(!window.localStorage.getItem('ngintip')) {

      this.dialog.loading = true;
      const groups = await this.getGroupsData();
      this.options.groups = groups.records.map(group => {
        return {
          value: group.id,
          label: group.name
        }
      })
      this.dialog.loading = false;
    }
  },
  validations: {
    form: {
      group_id: { required }
    }
  },
  methods: {
    openDialog() {
      this.form = {};
      this.tree.data = [];
      this.dialog.open = true;
      // console.log(this.key);
    },
    setCameraData(data) {
      this.camera = data; 
    },
    getGroupsData() {
      return new Promise((resolve, reject) => {
        this.$request.get('records/groups')
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    async onSelectGroup(val) {
      this.dialog.loading = true;
      this.tree.data = [];
      const groupData = await this.getGroupDetail(val);
      const users = groupData.users.map(user => {
        return {
          ...user,
          label: `${user.full_name} / ${user.email} / ${user.phone_number}` 
        }
      })
      this.tree.data.push({
        id: 0,
        label: groupData.name,
        children: users
      });
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([0]);
      })
      this.dialog.loading = false;
    },
    // onTreeCheck(checkedNodes, props) {
    //   this.tree.checked.keys = props.checkedKeys;
    //   this.tree.checked.nodes = props.checkedNodes;
    // },
    getGroupDetail(groupId) {
      return new Promise((resolve, reject) => {
        const searchParams = new URLSearchParams();
        searchParams.append('join', 'users');
        this.$request.get(`records/groups/${groupId}`, { params: searchParams })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    async onOkClick() {
      if(this.state === 'form') {
        this.dialog.label = {
          ok: 'Kirim Notifikasi',
          cancel: 'Back'
        }
        this.state = 'confirm';
        // console.log()
        this.tree.checked.nodes = this.$refs.tree.getCheckedNodes(true)
        this.tree.checked.keys = this.$refs.tree.getCheckedKeys()

        this.message.original = this.form.message;

        // this.prefixMessage = `[CCTV Kominfo Kota Malang]`;
        // this.postfixMessage = `Kunjungi link berikut untuk melihat kondisi kamera CCTV terkait: ${window.location.origin}/dashboard/map?cameraId=xxx`;
        // this.form.message = [this.prefixMessage, this.form.message, this.postfixMessage].join('<br>');

        
        this.dialog.loading = true;
        this.uploadImage().then(async response => {
          this.dialog.loading = false;
          this.imageCloudinary = response;
          await this.urlFactory();
          this.messageFactory();
        }).catch(() => {
          this.dialog.loading = false;
        });

      } else {
        this.doSend();
      }
    },
    urlFactory() {
      return new Promise(resolve => {
        this.url.image = this.imageCloudinary.secure_url;
        this.url.image = this.url.image.replace('/upload/', '/upload/l_diskominfo-logo_solqth,g_north_east,x_50,y_50/');
        this.url.camera = `${window.location.origin}/dashboard/map?cid=${this.camera.id}`;
        resolve(true);
        // console.log(this.url);
      })
    },
    messageFactory() {
      this.htmlMessageFactory();
      this.encodedMessageFactory();
    },
    messageReplaceJoin() {

    },
    htmlMessageFactory() {
      let replacedPostfixes = [];
      this.message.postfixes.forEach(postfix => {
        let str = null;
        str = postfix.replace('{camera_url}', this.url.camera);
        str = str.replace('{image_url}', this.url.image);
        replacedPostfixes.push(str);
      });
      const prefix = this.message.prefixes.join('<br><br>');
      const postfix = replacedPostfixes.join('<br><br>');

      this.message.html = [prefix, this.message.original, postfix].join('<br><br>');
      // console.log(this.message.html);

    },
    encodedMessageFactory() {
      let replacedPostfixes = [];
      this.message.postfixes.forEach(postfix => {
        let str = postfix.replace('{camera_url}', this.url.camera);
        str = str.replace('{image_url}', this.url.image);
        replacedPostfixes.push(str);
      });
      const prefix = this.message.prefixes.join('<br><br>');
      const postfix = replacedPostfixes.join('<br><br>');
      let message = [prefix, this.message.original, postfix].join('<br><br>');
      message = encodeURI(message);
      message = message.replace(/%3Cbr%3E/g, '%0a');
      this.message.encoded = message;
    },
    onCancelClick() {
      if(this.state === 'form') {
        this.dialog.open = false;
      } else {
        this.state = 'form';
        this.dialog.label = {
          ok: 'Next',
          cancel: 'Cancel'
        }
        this.$nextTick(() => {
          // this.form.message = this.form.messageOriginal;
          this.form.message = this.message.original;
          this.$refs.tree.setCheckedKeys(this.tree.checked.keys);
          this.imageCloudinary = null;
          this.url.image = null;
        });
      }
    },
    uploadImage() {
      return new Promise((resolve, reject) => {
        const data = {
          url: `http://proxy.cctv.malangkota.go.id/image?host=${this.camera.host}`
        }
        this.$request.post('upload-image', data).then(response => {
          resolve(response.data);
        }).catch(err => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          });
          reject(err);
        })
      });
      // console.log(this.camera);
      // return this.$request.post('upload-image')
    },
    onDialogClose() {
      this.state = 'form';
      this.url.image = null;
    },
    async doSend() {
      this.dialog.loading = true;
      // console.log(this.tree.checked.nodes);
      if(this.enable.email) {
        await this.emailBroadcast();  
      }
      if(this.enable.whatsapp) {
        this.whatsappBroadcast();  
      }
      this.dialog.loading = false;
      this.dialog.open = false;
    },
    emailBroadcast() {
      const recipients = this.tree.checked.nodes.map(user => {
        return {
          email: user.email,
          name: user.full_name
        }
      });
      const data = {
        recipients,
        subject: 'Notifikasi CCTV Kominfo Malang Kota',
        content: {
          type: 'text/html',
          value: this.message.html
        }
      }
      return new Promise((resolve, reject) => {
        this.$request.post('send-email', data).then(response => {
          this.$message({
            type: 'success',
            message: 'Notifikasi via email telah terkirim dengan sukses!'
          });
          resolve(response.data);
        }).catch(err => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          });
          reject(err);
        })
      })
    },
    whatsappBroadcast() {
      this.tree.checked.nodes.forEach(user => {
        let link = document.createElement('a');
        link.href = `https://api.whatsapp.com/send?phone=${user.phone_number}&text=${this.message.encoded}`;
        link.target  = '_blank';
        link.click();
      })
    }
  }
}
</script>

<style>

</style>